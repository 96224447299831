<template>
    <div
    style="
      width: 100%;
      height: 100%;
      background-color: #2d3a4b;
      overflow: hidden;
    "
  >
    
    <div style="width: 300px;position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-50%,-50%);transform: translate(-50%,-50%);">
      <h3 style="text-align: center;padding-bottom: 10px;color: #fff;font-size: 20px;letter-spacing: 5px;text-indent: 0px;">企信通短信管理平台</h3>

      <el-form :rules="rules" status-icon :model="form_data" >
        <el-form-item style="margin-bottom: 15px;" prop="login">
           
            <el-input v-model="form_data.login" placeholder="请输入用户名" autocomplete="off" prefix-icon="el-icon-user" size="small"></el-input>
        </el-form-item>

        <el-form-item style="margin-bottom: 15px;" prop="pwd">
            <el-input type="password" v-model="form_data.pwd" placeholder="请输入密码" autocomplete="off" prefix-icon="el-icon-unlock"  size="small"></el-input>
        </el-form-item>


        <div class="imgcode" :style="'position:relative;background:url(' +imgsrc +');background-size:cover;'"ref="parentdiv">
            <!-- v-move="{ VerificationFun, set }":兼容手机浏览器  @mousedown="move" 触发的是PC端   -->
            <div class="moveimg" ref="moveimg" :style="'background:url(' + movesrc + ');background-size:cover;'"
              @mousedown="move"
              v-move="{ VerificationFun, set }" 
            ></div>
        </div>

        <el-form-item size="large" style="text-align:center" >
            <el-button type="primary" :disabled="login_button_disabled" @click="onSubmit" round>登录</el-button>
        </el-form-item>


      </el-form>
      
    </div>
  </div>
   
                
       
</template>
<script>
    import API from '../api/api';
    // import Imgcode from '../components/imgcode';
    import axios from "axios";
  
    //import { MessageBox } from "element-ui";
   
    import {
        setCookie,
        delCookie,
        setItem,
        removeAllItem
    } from '../api/tool.js'
 
    export default {
        name: "Login",
        components: {
            // Imgcode,
            
        },
        data() {
            return {
                form_data :{
                    login:"",//用户名
                    pwd: "",//密码
                },
                rules:{//规则
                    login: [
                        { required: true, message: '请输入用户名', trigger: 'blur' },
                        { min: 2, max: 32, message: '长度在 2 到 32 个字符', trigger: 'blur' }
                    ],
                    pwd:[
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { min: 1, max: 32, message: '长度在 1 到 32 个字符', trigger: 'blur' }
                    ]
                },
                login_button_disabled : true,//登录按钮 是否禁用

                imgsrc:"", //图形验证码的背景图
                movesrc:"",//图形验证码的背景图中的小图
                loginid:"",//图形验证码的验证的唯一值
                positionX:"",//图形验证码的X轴移动像素
                positionY:"",//图形验证码的Y轴移动像素

            }
        },
        //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
        created() {
            // console.log("我是created2");
            removeAllItem();//本地存储-全部删除
            delCookie("qxt_session");
        },
        activated() {
            // console.log("我是activated钩子");
            this.getCheckImage();
        },
        deactivated() {
            // console.log("我是deactivated钩子");
        },
        methods: {
            //登录 提交 -- 请求接口
            onSubmit(){
                //请求接口
                API.ManagerUserServlet({
                    param: "login",
                    x:this.positionX,
                    y:this.positionY,
                    login:this.form_data.login,
                    password:this.form_data.pwd,
                    loginid:this.loginid
                }).then((res) => {
                    //console.log(res);
                    if (res.recode === 0) {
                        var session = res.session;
                        //本地存储
                        // setItemSession(session);
                        // setItem("login_name",this.form_data.login);

                        setItem("qxt_username", res.name);
                        setItem("power_level",res.power_level);
                        setItem("qxt_session", res.session);


                        setCookie("qxt_username", res.name);
                        setCookie("qxt_power_level",res.power_level);
                        setCookie("qxt_session", res.session);

                        
                        
                        //跳转到菜单页面
                        API.menu();
                        
                        // this.$refs.moveimg.style.left = "50%";
                        // this.$refs.moveimg.style.top = "50%";
                    } else {
                        // this.$Message.error(res.text);

                        this.getCheckImage();
                    }
                   
                });

            },
            //获取验证码图片-- 请求接口
            getCheckImage(){
                console.log("获取验证码图片-- 请求接口");
                //请求接口
                API.getImage({
                    param: ""
                }).then((res) => {
                    console.log("获取验证码图片-- 请求接口222:"+res.recode);
                    //console.log(res);
                    if (res.recode === 0) {
                        // this.imgsrc = res.bg;
                        // this.movesrc = res.front;

                        this.imgsrc = axios.defaults.baseURL + res.bg;
                        this.movesrc =  axios.defaults.baseURL + res.front;
                        this.loginid = res.loginid;
                        // this.$refs.moveimg.style.left = "50%";
                        // this.$refs.moveimg.style.top = "50%";
                    } else {
                        // this.$Message.error(res.text);

                    }
                   
                });
            },
            //图片结果 验证 -- 请求接口
            VerificationFun(x, y) {
                API.getImage( {
                    x: parseInt(x),
                    y: parseInt(y),
                    param: "validate",
                    loginid: this.loginid
                })
                .then((res) => {
                    if (res.recode === 0) {

                        this.login_button_disabled = false;//登录按钮打开
                        //this.$alert("测试");
                        
                    } else {
                        this.login_button_disabled = true;//登录按钮禁用
                        //获取验证码图片
                        this.getCheckImage();
                    }
                })
                .catch((err) => {
                    this.login_button_disabled = true;//登录按钮禁用
                    console.log(err);
                });
            },

            
            move(e) {
                let odiv = e.target; //获取目标元素
                //算出鼠标相对元素的位置
                let disX = e.clientX - odiv.offsetLeft;
                let disY = e.clientY - odiv.offsetTop;
                document.onmousemove = (e) => {
                    //鼠标按下并移动的事件
                    var e = e || window.event;
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let left = e.clientX - disX;
                    let top = e.clientY - disY;

                    //移动当前元素
                    odiv.style.left = left + "px";
                    odiv.style.top = top + "px";
                    if (left <= 50) {
                        odiv.style.left = 50 + "px";
                    }
                    if (top <= 50) {
                        odiv.style.top = 50 + "px";
                    }
                    if (left >= 250) {
                        odiv.style.left = 250 + "px";
                    }
                    if (top >= 250) {
                        odiv.style.top = 250 + "px";
                    }
                };
                document.onmouseup = (e) => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                    this.positionX = odiv.style.left;
                    this.positionY = odiv.style.top;

                    let x = parseInt(this.positionX.replace("px", "")) - 50,
                        y = parseInt(this.positionY.replace("px", "")) - 50;
                    this.VerificationFun(x, y);
                    this.set(x, y);
                };
            },
            set(x, y) {
                this.positionX = x;
                this.positionY = y;
            },
            
          
            
        },
        directives: { //指令 手机触发
            move(el, binding) { 
                // console.log("binding.value---");
                // console.log(binding.value);
                let touch, disX, disY;
                el.ontouchstart = (e) => {
                if (e.touches) {
                    touch = e.touches[0];
                } else {
                    touch = e;
                }
                //算出鼠标相对元素的位置
                disX = touch.clientX - el.offsetLeft;
                disY = touch.clientY - el.offsetTop;
                };
                el.ontouchmove = (e) => {
                if (e.touches) {
                    touch = e.touches[0];
                } else {
                    touch = e;
                }
                //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                let left = touch.clientX - disX;
                let top = touch.clientY - disY;
                //移动当前元素
                el.style.left = left + "px";
                el.style.top = top + "px";
                e.preventDefault(); //阻止页面的滑动默认事件

                if (left <= 50) {
                    el.style.left = 50 + "px";
                }
                if (top <= 50) {
                    el.style.top = 50 + "px";
                }
                if (left >= 250) {
                    el.style.left = 250 + "px";
                }
                if (top >= 250) {
                    el.style.top = 250 + "px";
                }
                };
                el.ontouchend = (e) => {
                    let x = parseInt(el.style.left.replace("px", "")) - 50;
                    let y = parseInt(el.style.top.replace("px", "")) - 50;
                    //console.log(x, y);
                    //console.log(binding.oldValue);
                    binding.oldValue.VerificationFun(x, y);
                    binding.oldValue.set(x, y);
                };
            },
        },
    }
</script>
<style lang="scss" scoped>
    .imgcode {
      width: 300px;
      height: 300px;
      margin-bottom: 20px;
    }

    .moveimg {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.5;
      border: 1px solid rgb(0, 0, 0);
    }
</style>